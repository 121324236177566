import { createSlice } from '@reduxjs/toolkit';
// import { extendedApiSlice } from '../services/movieServerApi'
export interface AddTvShowState {
  currentStep: number | null;
  selectedAddType: string | null;
  selectedDataSource: string | null;
  selectedItunesResult: any;
  selectedImdbResult: any;
  selectedItunesCountry: string | null;
  generalTvShowData: any;
  secondaryTvShowData: any;
  castCrewMovieData: any;
  imagesMovieData: any;
  languagesMovieData: any;
  chaptersMovieData: any;
  finalTvShowData: any;
  studios: any[];
  ratings: any[];
  qualities: any[];
  formats: any[];
  sources: any[];
  genres: any[];
  // networks: any[];
  countries: any[];
  tags: any[];
  collections: any[];
  languages: any[];
  accessLevels: any[];
  newTvShowId: string;
}

const initialState: AddTvShowState = {
  currentStep: 1,
  selectedAddType: null,
  selectedDataSource: null,
  selectedItunesResult: null,
  selectedImdbResult: null,
  selectedItunesCountry: null,
  generalTvShowData: null,
  secondaryTvShowData: null,
  castCrewMovieData: null,
  imagesMovieData: null,
  languagesMovieData: null,
  chaptersMovieData: { hasNamedChapters: false },
  finalTvShowData: {},
  studios: [],
  ratings: [],
  qualities: [],
  formats: [],
  sources: [],
  genres: [],
  countries: [],
  // networks: [],
  tags: [],
  collections: [],
  languages: [],
  accessLevels: [],
  newTvShowId: '',
};

export const addTvShowSlice = createSlice({
  name: 'addTvShow',
  initialState,
  reducers: {
    setSelectedItunesAndImdbResults: (state, { payload }) => {
      state.selectedItunesResult = payload.selectedItunesResult;
      state.selectedImdbResult = payload.selectedImdbResult;
      state.selectedItunesCountry = payload.selectedItunesCountry;
    },
    setCurrentStep: (state, { payload }) => {
      state.currentStep = payload;
    },
    setSelectedAddType: (state, { payload }) => {
      state.selectedAddType = payload;
    },
    setSelectedDataSource: (state, { payload }) => {
      state.selectedDataSource = payload;
    },
    setTvShowData: (state, { payload }) => {
      console.log('DA PAYLOAD:', payload);
      state.generalTvShowData = payload.generalTvShowData;
      state.secondaryTvShowData = payload.secondaryTvShowData;
      // state.castCrewMovieData = payload.castCrewMovieData;
      // state.languagesMovieData = payload.languagesMovieData;
      // state.studios = payload.studios;
      state.ratings = payload.ratings;
      // state.qualities = payload.qualities;
      // state.formats = payload.formats;
      // state.sources = payload.sources;

      state.genres = payload.genres;
      // state.networks = payload.networks;
      state.countries = payload.countries;
      state.tags = payload.tags;
      state.collections = payload.collections;

      // state.languages = payload.languages;
      state.accessLevels = payload.accessLevels;
    },
    setGeneralTvShowData: (state, { payload }) => {
      state.generalTvShowData = payload;
    },
    setSecondaryTvShowData: (state, { payload }) => {
      state.secondaryTvShowData = payload;
    },
    setCastCrewMovieData: (state, { payload }) => {
      state.castCrewMovieData = payload;
    },
    setImagesMovieData: (state, { payload }) => {
      state.imagesMovieData = payload;
    },
    setLanguagesMovieData: (state, { payload }) => {
      state.languagesMovieData = payload;
    },
    setChaptersMovieData: (state, { payload }) => {
      state.chaptersMovieData = payload;
    },
    setFinalTvShowData: (state, { payload }) => {
      state.finalTvShowData = payload;
    },
    setNewTvShowId: (state, { payload }) => {
      state.newTvShowId = payload;
    },
    resetTvShowState: () => initialState,
  },
});

export const {
  setSelectedItunesAndImdbResults,
  setCurrentStep,
  setSelectedAddType,
  setSelectedDataSource,
  setTvShowData,
  setGeneralTvShowData,
  setSecondaryTvShowData,
  setCastCrewMovieData,
  setImagesMovieData,
  setLanguagesMovieData,
  setChaptersMovieData,
  setFinalTvShowData,
  setNewTvShowId,
  resetTvShowState,
} = addTvShowSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default addTvShowSlice.reducer;
