import { Button, FormFileInput } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, FormFeedback, Row, Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IStep5FormInput } from '../../../../interfaces/forms/TVShowSeasonEpisodeForm';
import { usePatchTvShowSeasonEpisodeMutation } from '../../../../app/services/tvShowServerApi';
import { setCurrentTvShowSeasonEpisodeStep } from '../../../../app/slices/addTvShowSeasonEpisodeSlice';

// export const MovieForm = ({ data }: any) => {
export const Step5 = () => {
  const dispatch = useAppDispatch();

  const imagesTvShowSeasonEpisodeData = useAppSelector(
    (state: RootState) =>
      state.addTvShowSeasonEpisode.imagesTvShowSeasonEpisodeData,
  );

  const {
    control,
    handleSubmit,
    // register,
    // reset,
    formState: { errors },
  } = useForm<IStep5FormInput>({
    defaultValues: imagesTvShowSeasonEpisodeData,
  });

  const [patchTvShowSeasonEpisode, { isLoading, isError, isSuccess, error }] =
    usePatchTvShowSeasonEpisodeMutation();

  // const newMovieId = useAppSelector(
  //   (state: RootState) => state.addMedia.newMovieId,
  // );

  const onSubmit: SubmitHandler<IStep5FormInput> = (formdata) => {
    // dispatch(setImagesMovieData(formdata));
    // dispatch(setCurrentMovieStep(8));
    console.log('DATA MOVIE STEP 7:', formdata);
    // formdata.background = e.target.background.files
    // createMovie(formdata)

    // const formData = new FormData()
    // // formData.append('id', newMovieId)
    // formData.append('poster', formdata.poster ?? "")
    // formData.append('background', formdata.background)
    formdata.step = 'images_data';

    patchTvShowSeasonEpisode(formdata).then(function (result: any) {
      if (result && result.data) {
        // dispatch(setNewMovieId(result.data.movieId));
        dispatch(setCurrentTvShowSeasonEpisodeStep(6));
      }
    });
  };

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="cover">{'Cover *'}</Label>
              <FormFileInput
                control={control}
                name="cover"
                id="cover"
                placeholder={"Select the episode's cover"}
                required={true}
                error={!!errors.cover}
                accept=".jpg"
              />
              <FormFeedback>{"The episode's cover is required"}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};
