import { Button, FormSelect, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { IMovieStep3FormInput } from '../../../../interfaces/forms/IMovieStep3Form';
import { itunesCountries } from '../../../../shared/constants';
import { useGetItunesAndImdbMovieOptionsQuery } from '../../../../app/services/movieServerApi';
import { useState } from 'react';
import errorIcon from '../../../../assets/error.svg';

import { MovieSearchResults } from './MovieSearchResults';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  setCurrentMovieStep,
  setSelectedItunesAndImdbResults,
} from '../../../../app/slices/addMediaSlice';
import { RootState } from '../../../../app/store';

export const MovieStep3 = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IMovieStep3FormInput>({
    defaultValues: {
      movieTitle: '',
      itunesCountry: null,
    },
  });

  const [currentSearchParams, setCurrentSearchParams] = useState({});
  const [
    skipGetItunesAndImdbMovieOptionsQuery,
    setSkipGetItunesAndImdbMovieOptionsQuery,
  ] = useState(true);

  const [selectedItunesResult, setSelectedItunesResult] = useState<any | null>(
    null,
  );
  const [selectedImdbResult, setSelectedImdbResult] = useState<any | null>(
    null,
  );
  const [showResultsContainer, setShowResultsContainer] = useState(false);

  const {
    data = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetItunesAndImdbMovieOptionsQuery(currentSearchParams, {
    skip: skipGetItunesAndImdbMovieOptionsQuery,
  });

  const dispatch = useAppDispatch();

  const [selectedItunesCountry, setSelectedItunesCountry] = useState<
    string | null
  >(null);

  const onSubmit: SubmitHandler<IMovieStep3FormInput> = (data) => {
    setSelectedItunesCountry(
      data.itunesCountry ? data.itunesCountry.value : null,
    );
    setShowResultsContainer(true);
    setCurrentSearchParams(data);
    setSkipGetItunesAndImdbMovieOptionsQuery(false);
    console.log('DATA MOVIE STEP 3:', data);
  };

  const setMovieSearchData = () => {
    console.log('selectedItunesResult:', selectedItunesResult);
    console.log('selectedImdbResult:', selectedImdbResult);
    dispatch(
      setSelectedItunesAndImdbResults({
        selectedItunesResult: selectedItunesResult
          ? selectedItunesResult.id
          : null,
        selectedImdbResult: selectedImdbResult!.id,
        selectedItunesCountry: selectedItunesCountry,
      }),
    );
    dispatch(setCurrentMovieStep(4));
  };

  const selectedDataSource = useAppSelector(
    (state: RootState) => state.addMedia.selectedDataSource,
  );

  const clearSelectedOptions = () => {
    setSelectedItunesResult(null);
    setSelectedImdbResult(null);
  };

  const enableButton =
    (selectedDataSource === 'itunesAndImdb' &&
      selectedItunesResult &&
      selectedImdbResult) ||
    (selectedDataSource === 'justImdb' && selectedImdbResult);

  return (
    <>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="movieTitle">Movie Title *</Label>
            <Controller
              name="movieTitle"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="movieTitle"
                  placeholder="Enter the movie title"
                  type="text"
                  invalid={!!errors.movieTitle}
                  {...field}
                />
              )}
            />
            <FormFeedback>The movie title is required</FormFeedback>
          </FormGroup>
          {selectedDataSource === 'itunesAndImdb' && (
            <FormGroup>
              <Label for="itunesCountry">iTunes Country *</Label>
              <Controller
                name="itunesCountry"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={itunesCountries}
                    placeholder="Select an iTunes Country"
                    error={errors.itunesCountry}
                  />
                )}
              />
              {errors.itunesCountry && (
                <div className="invalid-message">
                  The iTunes country is required
                </div>
              )}
            </FormGroup>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" onClick={() => clearSelectedOptions()}>
              Search
            </Button>
          </div>
        </Form>
      </div>
      {showResultsContainer && (
        <div className="media-section media-mt-1">
          {isLoading || isFetching ? (
            <div className="list-no-item-add">
              <LoadingSpinner />
            </div>
          ) : isError ? (
            <div className="list-no-item-add">
              <img src={errorIcon} alt="error-icon" />
              <p>{error?.error}</p>
            </div>
          ) : Object.keys(data).length !== 0 ? (
            <MovieSearchResults
              data={data}
              selectedItunesResult={selectedItunesResult}
              selectedImdbResult={selectedImdbResult}
              setSelectedItunesResult={setSelectedItunesResult}
              setSelectedImdbResult={setSelectedImdbResult}
              selectedDataSource={selectedDataSource}
            />
          ) : null}
        </div>
      )}
      {enableButton && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setMovieSearchData()}>Submit</Button>
        </div>
      )}
    </>
  );
};
