import './PageHeader.scss';
import bg from '../../assets/sample_background_image.jpg';
import { IPageHeader } from '../../interfaces/components';

export const PageHeader = ({ title }: IPageHeader) => {
  return (
    <div className="page-header" style={{ backgroundImage: `url(${bg})` }}>
      <h2 className="media-h2">{title}</h2>
    </div>
  );
};
