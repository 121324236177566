import './TVShowSeasonCard.scss';

import { Link } from 'react-router-dom';

import { Button } from '../../components';

// import { category } from '../../api/tmdbApi';
// import apiConfig from '../../api/apiConfig';
// import { useDispatch, useSelector } from 'react-redux'

export const TVShowSeasonCard = (props: any) => {
  const item = props.item;

  const link = '/tv-shows/season/' + item.id;

  // const bg = apiConfig.w500Image(item.poster_path || item.backdrop_path);
  const background = item.cover;
  // const dispatch = useDispatch()

  return (
    <Link className="media-a" to={link}>
      <div
        className="tv-show-season-card"
        style={{ backgroundImage: `url(${background})` }}
      >
        <Button className="btn">
          <i className="bi bi-play-fill"></i>
        </Button>
      </div>
      <h3 className="media-h3">Season {item.number}</h3>
    </Link>
  );
};
