import { useController } from 'react-hook-form';
import IFormFileInput from '../../interfaces/components/IFormFileInput';
import { Input } from 'reactstrap';
// import { useState } from 'react';
export const FormFileInput = ({
  control,
  name,
  id,
  placeholder,
  required,
  error,
  accept,
}: IFormFileInput) => {
  const { field } = useController({
    control,
    name,
    rules: { required: required },
  });
  // const [value, setValue] = useState('');
  return (
    //   <input
    //     type="file"
    //     value={value}
    //     onChange={(e) => {
    //       setValue(e.target.value);
    //       field.onChange(e.target.files);
    //     }}
    //   />

    <Input
      id={id}
      placeholder={placeholder}
      type="file"
      invalid={error}
      accept={accept}
      // value={value}
      // onChange={(e) =>
      //   field.onChange({ target: { value: e.target.files[0], name: field.name } })
      // }
      // value={value}
      // onChange={(e) => {
      //     // console.log(e.target.value)
      //     // console.log(e.target.files)
      //     setValue(e.target.value);
      //     // field.onChange(e.target.files.length=== 0 ? null : e.target.files.length);
      //     field.onChange(e.target.files.length=== 0 ? null : e.target.files[0]);

      //   }}

      onChange={(e) => {
        console.log('TARGET:', e.target);
        field.onChange({
          target: {
            value: e.target.files ? e.target.files[0] : undefined,
            name: field.name,
          },
        });
      }}
    />
  );
};
