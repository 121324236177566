import { useState } from 'react';
// import { useParams } from 'react-router';

import './MediaGrid.scss';
// import { useDispatch, useSelector } from 'react-redux'
// import { mediaGridSelector, fetchMediaGridItems, fetchFilteredMediaGridItems, setTitle, setPage } from '../../slices/mediaGrid'
import { MovieCard, LoadingSpinner, OutlineButton } from '../../components';
import { MediaSearch } from './MediaSearch';
import { useGetMoviesQuery } from '../../app/services/movieServerApi';
import errorIcon from '../../assets/error.svg';
import noDataIcon from '../../assets/nodata.svg';
import { useSearchParams } from 'react-router-dom';
// import { RootState } from '../../app/store';
// import { useAppSelector } from '../../app/hooks';

export const MovieCatalog = (props: any) => {
  // const { mediaGridItems, loading, hasErrors, isEmpty, page, totalPages, title, currentTitle, movieItems, tvShowItems, autographItems,
  //     moviePage, totalMoviePages, tvShowPage, totalTvShowPages, autographPage, totalAutographPages } = useSelector(mediaGridSelector)
  // const dispatch = useDispatch()
  // const selectedArtistId = props.selectedArtistId
  // useEffect(() => {
  //   dispatch(setPage(1))
  //   window.scrollTo(0, 0)
  //   console.log("selectedArtistId:", props.selectedArtistId)

  //   if (selectedArtistId) {
  //     dispatch(fetchFilteredMediaGridItems(props.category, 1, null, selectedArtistId))

  //   } else if (tag) {
  //     dispatch(fetchFilteredMediaGridItems(props.category, 1, null, null, tag))

  //   } else if (collection) {
  //     dispatch(fetchFilteredMediaGridItems(props.category, 1, null, null, null, collection))

  //   } else if (studio) {
  //     dispatch(fetchFilteredMediaGridItems(props.category, 1, null, null, null, null, studio))

  //   } else if (keyword) {
  //       dispatch(fetchFilteredMediaGridItems(props.category, 1, keyword))
  //   } else {
  //       dispatch(fetchMediaGridItems(props.category, 1))
  //   }

  // }, [dispatch, props.category, keyword, selectedArtistId, collection, tag, studio])

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [title])

  // const currentPage = useAppSelector(
  //   (state: RootState) => state.navigation.movieCurrentPage,
  // );
  // const totalPages = useAppSelector(
  //   (state: RootState) => state.navigation.movieTotalPages,
  // );
  // const currentPage = 1;
  // const totalPages = 2;

  const changePage = () => {
    // currentSearchParams.page = 2
    console.log('NEW OBJ 1:', currentSearchParams);
    const new_obj = {
      ...currentSearchParams,
      page: currentSearchParams.page + 1,
    };
    console.log('NEW OBJ 2:', new_obj);
    setCurrentSearchParams(new_obj);
  };

  const renderLoadMoreButton = () => {
    if (data.currentPage < data.totalPages) {
      return (
        <div className="media-grid__loadmore">
          {/* <OutlineButton className="small" onClick={() => setPage(page+1)}>Load more</OutlineButton> */}
          <OutlineButton className="small" onClick={() => changePage()}>
            Load more
          </OutlineButton>
        </div>
      );
    } else {
      return null;
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  // const [keyword, setKeyword] = useState(searchParams.get("keyword") || "")

  const keyword = searchParams.get('keyword') || '';
  const tag = searchParams.get('tag') || '';
  const collection = searchParams.get('collection') || '';
  const studio = searchParams.get('studio') || '';
  // const [page, setPage] = useState(1)

  // const currentSearchParams = {page: page, keyword: keyword, tag: tag, collection: collection, studio: studio}

  const [currentSearchParams, setCurrentSearchParams] = useState({
    page: 1,
    keyword: keyword,
    tag: tag,
    collection: collection,
    studio: studio,
  });

  // useEffect(() => {
  //   setPage(1)
  // }, [keyword])
  // console.log("SET PAGE:", page)

  const {
    data = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetMoviesQuery(currentSearchParams);
  // const testum = useGetMoviesQuery(currentSearchParams);
  // console.log("TESTUM:", testum)

  // const serializeFormQuery = (title: string) => {
  //   return {keyword: title}
  // }
  // useEffect(() => {
  //   let params = serializeFormQuery(keyword);
  //   setSearchParams(params)
  // }, [keyword])

  // const allMovieItems: any[] = []
  // useEffect(() => {
  //   console.log("ALL MOVIE ITEMS 1:", allMovieItems)
  //   console.log("MOVIE ITEMS:", movieItems)
  //   allMovieItems.push(...movieItems)
  //   console.log("ALL MOVIE ITEMS 2:", allMovieItems)
  // }, [movieItems])

  console.log('MOVIE ITEMS:', data.results);

  return (
    <>
      <div className="section mb-3">
        <MediaSearch
          category={props.category}
          setCurrentSearchParamsFunction={setCurrentSearchParams}
          setParamsFunction={setSearchParams}
          currentSearchParams={currentSearchParams}
        />
      </div>

      {isLoading || isFetching ? (
        <div className="list-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : data.results.length === 0 ? (
        <div className="list-no-item-single">
          <img src={noDataIcon} alt="no-item-icon" />
          <p>{'No Items'}</p>
        </div>
      ) : (
        <div className="media-grid">
          {data.results.map((item: any, i: number) => (
            <MovieCard category={'movies'} item={item} key={i} />
          ))}
        </div>
      )}

      {renderLoadMoreButton()}
    </>
  );
};
