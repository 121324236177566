import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useEffect } from 'react';
import {
  setCurrentTvShowSeasonStep,
  setSelectedTvShowResult,
} from '../../../../app/slices/addTvShowSeasonSlice';
import { setSelectedMediaType } from '../../../../app/slices/addMediaSlice';
import { resetTvShowState } from '../../../../app/slices/addTvShowSlice';

export const Step7 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const newTvShowId = useAppSelector(
    (state: RootState) => state.addTvShow.newTvShowId,
  );
  const generalTvShowData = useAppSelector(
    (state: RootState) => state.addTvShow.generalTvShowData,
  );

  const addSeasonSetup = () => {
    dispatch(
      setSelectedTvShowResult({
        id: newTvShowId,
        imdbId: generalTvShowData.imdbId,
        title: generalTvShowData.title,
      }),
    );
    dispatch(setCurrentTvShowSeasonStep(2));
    dispatch(setSelectedMediaType('tvShowSeason'));
  };

  const finalSetup = () => {
    navigate(`/tv-shows/${newTvShowId}`);
    dispatch(resetTvShowState());
  };
  return (
    <div className="add-media-form-container">
      <div className="buttons-container">
        <Button onClick={() => addSeasonSetup()}>Add Season</Button>
        <Button onClick={() => finalSetup()}>Go To New TV Show Page</Button>
      </div>
    </div>
  );
};
