import { useState } from 'react';
import { Button, OutlineButton, TrailerModal } from '../';
import './MediaSlide.scss';
import { useNavigate } from 'react-router';
import { getOverviewFontSize, getTitleFontSize } from '../../shared/utils';

export const MediaSlideItem = (props: any) => {
  const navigate = useNavigate();
  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  return (
    <>
      <div
        className={`media-slide__item ${props.className}`}
        style={{
          backgroundImage: `url(${props.item.background ? props.item.background : props.item.cover})`,
        }}
      >
        <div className="media-slide__item__content media-container">
          <div className="media-slide__item__content__info">
            <h2
              className="title"
              style={{ fontSize: getTitleFontSize(props.item.title) }}
            >
              {props.item.title}
            </h2>
            <div
              className="overview"
              style={{
                fontSize: getOverviewFontSize(props.item.shortDescription),
              }}
            >
              {props.item.shortDescription}
            </div>
            <div className="btns">
              <Button onClick={() => navigate('/movies/' + props.item.id)}>
                Watch now
              </Button>
              <OutlineButton onClick={toggleTrailerModal}>
                Watch trailer
              </OutlineButton>
            </div>
          </div>
          <div className="media-slide__item__content__poster">
            <img src={props.item.cover} alt="" />
          </div>
        </div>
      </div>
      <TrailerModal
        isOpen={openTrailerModal}
        toggle={toggleTrailerModal}
        item={props.item}
      />
    </>
  );
};
