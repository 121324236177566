// import { useEffect } from 'react';
import { useParams } from 'react-router';
// import { useDispatch } from 'react-redux';
import '../Detail/Detail.scss';
import {
  Button,
  OutlineButton,
  AutographList,
  ArtistList,
  LoadingSpinner,
} from '../../components';
// import { mediaDetailSelector, fetchautographItem, modalToggle } from '../../slices/mediaDetail'
// import DetailMyModal from "../../components/modal/DetailMyModal"
// import MediaModal from "../../components/modal/MediaModal"
import { Link } from 'react-router-dom';
import { useGetAutographQuery } from '../../app/services/autographServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getOriginalTitleFontSize,
  getTitleFontSize,
} from '../../shared/utils';

export const AutographDetail = () => {
  const { category, id } = useParams();
  // const { trailerModalOpen, mediaModalOpen } = useSelector(mediaDetailSelector)
  const {
    data: autographItem = {},
    isLoading,
    // isFetching,
    isError,
    error,
  } = useGetAutographQuery(id);

  // const dispatch = useDispatch();

  const cover = autographItem.poster
    ? autographItem.poster
    : autographItem.image;
  const background = autographItem.background
    ? autographItem.background
    : autographItem.cover
      ? autographItem.cover
      : autographItem.image;

  const renderSimilarItems = () => {
    return (
      <AutographList
        category={category}
        type="similar"
        id={autographItem.id}
      />
    );
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   // dispatch(fetchautographItem(category, id))
  // }, [dispatch, category, id]);

  return (
    <>
      {isLoading ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <div>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      autographItem.title || autographItem.name,
                    ),
                  }}
                >
                  {autographItem.title || autographItem.name}
                </h1>
                {autographItem.originalTitle ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(
                        autographItem.originalTitle,
                      ),
                    }}
                  >
                    Original Title: {autographItem.originalTitle}
                  </h3>
                ) : null}

                <div className="genres">
                  <span className="badge genres__item">
                    {autographItem.releaseDate}
                  </span>
                  <span className="badge genres__item">
                    {autographItem.runtime}
                  </span>
                  <span className="badge genres__item">
                    {autographItem.genres && autographItem.genres[0].name}
                  </span>
                  <span className="badge genres__item">
                    {autographItem.rating && autographItem.rating.name}
                  </span>
                  <span className="badge genres__item">
                    IMDB Rating: {autographItem.imdbRating}
                  </span>
                  <span className="badge genres__item">
                    {autographItem.quality && autographItem.quality.name}
                  </span>
                  <span className="badge genres__item">
                    {autographItem.movieLanguages &&
                      autographItem.movieLanguages[0].language.name}
                  </span>
                </div>

                {/* <p className="overview"                   style={{
                    fontSize: getOverviewFontSize(autographItem.description),
                  }}>{autographItem.description}</p> */}

                <div className="btns">
                  <Button onClick={() => console.log(1)}>
                    {' '}
                    {/* dispatch(modalToggle()) */}
                    Watch now
                  </Button>
                  <OutlineButton onClick={() => console.log(2)}>
                    {' '}
                    {/* dispatch(modalToggle('trailer')) */}
                    Watch trailer
                  </OutlineButton>
                </div>
              </div>

              <div className="movie-content__poster">
                <a href={cover} target="_blank" rel="noreferrer">
                  {autographItem.orientation === 1 ? (
                    <img
                      className="movie-content__poster__img_autograph_horizontal"
                      src={cover}
                      alt={autographItem.title || autographItem.name}
                    ></img>
                  ) : (
                    <img
                      className="movie-content__poster__img_autograph_vertical"
                      src={cover}
                      alt={autographItem.title || autographItem.name}
                    ></img>
                  )}
                </a>
              </div>
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Directors'}</h2>
              </div>
              <ArtistList
                artists={autographItem.movieDirectors}
                category="director"
              />
            </div>
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Cast'}</h2>
              </div>
              <ArtistList
                artists={autographItem.movieActors}
                category="actor"
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Producers'}</h2>
              </div>
              <ArtistList
                artists={autographItem.movieProducers}
                category="producer"
              />
            </div>
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Screenwriters'}</h2>
              </div>
              <ArtistList
                artists={autographItem.movieScreenwriters}
                category="screenwriter"
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Genres'}</h2>
              </div>
              <div className="genres">
                {autographItem.genres &&
                  autographItem.genres.map((genre: any, i: number) => (
                    <span key={i} className="badge genres__item">
                      {genre.name}
                    </span>
                  ))}
              </div>
              <div className="media-section__header">
                <h2 className="subtitle">{'Languages'}</h2>
              </div>
              <div className="genres">
                {autographItem.movieLanguages &&
                  autographItem.movieLanguages.map(
                    (movieLanguage: any, i: number) =>
                      movieLanguage.audioTrack && (
                        <span key={i} className="badge genres__item">
                          {movieLanguage.language.name}
                        </span>
                      ),
                  )}
              </div>

              <div className="media-section__header">
                <h2 className="subtitle">{'Subtitles'}</h2>
              </div>
              <div className="genres">
                {autographItem.movieLanguages &&
                  autographItem.movieLanguages.map(
                    (movieLanguage: any, i: number) =>
                      movieLanguage.subtitle && (
                        <span key={i} className="badge genres__item">
                          {movieLanguage.language.name}
                        </span>
                      ),
                  )}
              </div>
            </div>

            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>
              <div className="genres">
                <span className="badge genres__item">
                  Studio: {autographItem.studio && autographItem.studio.name}
                </span>
              </div>
              <div className="genres">
                <p className="overview">
                  Copyright: {autographItem.copyright}
                </p>
              </div>

              <div className="media-section__header">
                <h2 className="subtitle">{'Tags'}</h2>
              </div>
              <div className="genres">
                {autographItem.tags &&
                  autographItem.tags.map((tag: any, i: number) => (
                    <Link
                      className="media-a"
                      to={'/autographs/search/tag/' + tag.name}
                      key={i}
                    >
                      <span key={i} className="badge genres__item">
                        {tag.name}
                      </span>
                    </Link>
                  ))}
              </div>
              <div className="media-section__header">
                <h2 className="subtitle">{'Collections'}</h2>
              </div>
              <div className="genres">
                {autographItem.collections &&
                  autographItem.collections.map(
                    (collection: any, i: number) => (
                      <Link
                        className="media-a"
                        to={'/autographs/search/collection/' + collection.name}
                        key={i}
                      >
                        <span key={i} className="badge genres__item">
                          {collection.name}
                        </span>
                      </Link>
                    ),
                  )}
              </div>
            </div>
          </div>

          <div className="detail-container">
            <div className="media-section mt-3">
              <div className="media-section__header">
                <h2 className="subtitle">Similar</h2>
              </div>
              {renderSimilarItems()}
            </div>
          </div>

          {/* <DetailMyModal isOpen={trailerModalOpen} item={autographItem}/> */}
          {/* <MediaModal isOpen={mediaModalOpen} item={autographItem}/> */}
        </div>
      )}
    </>
  );
};
