import { Button, LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { useGetTvShowOptionsQuery } from '../../../../app/services/tvShowServerApi';
import { useState } from 'react';
import errorIcon from '../../../../assets/error.svg';
import { TVShowSearchResults } from './TVShowSearchResults';
import { useAppDispatch } from '../../../../app/hooks';
import {
  setCurrentTvShowSeasonStep,
  setSelectedTvShowResult,
} from '../../../../app/slices/addTvShowSeasonSlice';
import { IStep1FormInput } from '../../../../interfaces/forms/TVShowSeasonForm/IStep1Form';

export const Step1 = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep1FormInput>({
    defaultValues: {
      tvShowTitle: '',
    },
  });

  const [currentSearchParams, setCurrentSearchParams] = useState({});
  const [skipGetTvShowOptionsQuery, setSkipGetTvShowOptionsQuery] =
    useState(true);

  const [selectedResult, setSelectedResult] = useState<any | null>(null);
  const [showResultsContainer, setShowResultsContainer] = useState(false);

  const {
    data = {},
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTvShowOptionsQuery(currentSearchParams, {
    skip: skipGetTvShowOptionsQuery,
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IStep1FormInput> = (data) => {
    setShowResultsContainer(true);
    setCurrentSearchParams(data);
    setSkipGetTvShowOptionsQuery(false);
    console.log('DATA MOVIE STEP 2:', data);
  };

  const setSearchData = () => {
    console.log('selectedResult:', selectedResult);
    dispatch(
      setSelectedTvShowResult({
        id: selectedResult.id,
        imdbId: selectedResult.imdbId,
        title: selectedResult.title,
      }),
    );
    dispatch(setCurrentTvShowSeasonStep(2));
  };

  const clearSelectedOptions = () => {
    setSelectedResult(null);
  };

  const enableButton = selectedResult;

  return (
    <>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="tvShowTitle">TV Show Title *</Label>
            <Controller
              name="tvShowTitle"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="tvShowTitle"
                  placeholder="Enter the tv show title"
                  type="text"
                  invalid={!!errors.tvShowTitle}
                  {...field}
                />
              )}
            />
            <FormFeedback>The tv show title is required</FormFeedback>
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit" onClick={() => clearSelectedOptions()}>
              Search
            </Button>
          </div>
        </Form>
      </div>
      {showResultsContainer && (
        <div className="media-section media-mt-1">
          {isLoading || isFetching ? (
            <div className="list-no-item-add">
              <LoadingSpinner />
            </div>
          ) : isError ? (
            <div className="list-no-item-add">
              <img src={errorIcon} alt="error-icon" />
              <p>{error?.error}</p>
            </div>
          ) : Object.keys(data).length !== 0 ? (
            <TVShowSearchResults
              data={data?.tvShowSearchResults}
              selectedResult={selectedResult}
              setSelectedResult={setSelectedResult}
            />
          ) : null}
        </div>
      )}
      {enableButton && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={() => setSearchData()}>Submit</Button>
        </div>
      )}
    </>
  );
};
