import './ArtistCard.scss';

import { Link } from 'react-router-dom';

import { Button } from '../../components';

// import { category } from '../../api/tmdbApi';
// import apiConfig from '../../api/apiConfig';
// import { useDispatch, useSelector } from 'react-redux';

// import basicUser from '../../assets/basic_user.svg';
import basicUser2 from '../../assets/basic_user_2.png';

export const ArtistCard = (props: any) => {
  // const item  = props.item;

  // const link = '/' + props.category + '/' + props.item.id;
  // const link = '/artists/' + props.item.id;

  const link =
    props.category === 'actor'
      ? '/artists/' + props.item.actor.id
      : props.category === 'director'
        ? '/artists/' + props.item.director.id
        : props.category === 'producer'
          ? '/artists/' + props.item.producer.id
          : '/artists/' + props.item.screenwriter.id;

  // const bg = apiConfig.w500Image(item.poster_path || item.backdrop_path);
  // const background = item.poster;
  // const dispatch = useDispatch();

  // console.log("ARTIST ITEM:", props.item)

  const artist =
    props.category === 'actor'
      ? props.item.actor
      : props.category === 'director'
        ? props.item.director
        : props.category === 'producer'
          ? props.item.producer
          : props.item.screenwriter;
  const role = props.item?.role;

  return (
    <div>
      <Link className="media-a" to={link}>
        <div
          className="artist-card"
          style={{
            backgroundImage: `url(${artist.photo ? artist.photo : basicUser2})`,
          }}
        >
          <Button className="btn">
            <i className="bi bi-play-fill"></i>
          </Button>
        </div>
        <h3 className="media-h3">{artist.name}</h3>
      </Link>
      {role && <h3 className="media-role">{role}</h3>}
    </div>
  );
};
