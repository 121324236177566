import { LoadingSpinner } from '../../../../components';
import '../../AddMedia.scss';
import errorIcon from '../../../../assets/error.svg';
import { useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { TVShowSeasonEpisodeForm } from './TVShowSeasonEpisodeForm';
import { useEffect } from 'react';
import { useGetTvSeasonEpisodeMediaDataQuery } from '../../../../app/services/tvShowServerApi';

export const Step3 = () => {
  //   const tvShowSeasonEpisodesData  = useAppSelector((state: RootState) => state.addTvShowSeasonEpisode.tvShowSeasonEpisodesData);
  const {
    data = {},
    isSuccess,
    isLoading,
    isFetching,
    isError,
    error,
  } = useGetTvSeasonEpisodeMediaDataQuery({
    selectedSeason: useAppSelector(
      (state: RootState) =>
        state.addTvShowSeasonEpisode.selectedTvShowSeasonResult.id,
    ),
    selectedEpisode: useAppSelector(
      (state: RootState) =>
        state.addTvShowSeasonEpisode.selectedTvShowSeasonEpisode,
    ),
  });
  console.log('data:', data);

  // const onSelect = (imdbId: string) => {
  //   dispatch(setSelectedTvShowSeasonEpisode(imdbId))
  //   dispatch(setCurrentTvShowSeasonEpisodeStep(3));
  //   // console.log('DATA MOVIE STEP 2:', data);
  // };

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  return (
    <>
      {isLoading || isFetching ? (
        <div className="list-no-item-add">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="list-no-item-add">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : isSuccess ? (
        <TVShowSeasonEpisodeForm />
      ) : null}
    </>
  );
};
