import { Button, FormSelect, FormFileInput } from '../../../../components';
import '../../AddMedia.scss';
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button as ButtonRs,
} from 'reactstrap';
import { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { usePatchTvShowSeasonEpisodeMutation } from '../../../../app/services/tvShowServerApi';
import Swal from 'sweetalert2';
import { ILoginFormError } from '../../../../interfaces/forms';
import { IStep6FormInput } from '../../../../interfaces/forms/TVShowSeasonEpisodeForm';
import { setCurrentTvShowSeasonEpisodeStep } from '../../../../app/slices/addTvShowSeasonEpisodeSlice';

export const Step6 = () => {
  const dispatch = useAppDispatch();
  const languagesTvShowSeasonEpisodeData = useAppSelector(
    (state: RootState) =>
      state.addTvShowSeasonEpisode.languagesTvShowSeasonEpisodeData,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep6FormInput>({
    defaultValues: languagesTvShowSeasonEpisodeData,
  });

  const {
    fields: languageFields,
    append: languageAppend,
    remove: languageRemove,
  } = useFieldArray({ control, name: 'languages', rules: { minLength: 1 } });

  const [patchTvShowSeasonEpisode, { isLoading, isError, isSuccess, error }] =
    usePatchTvShowSeasonEpisodeMutation();

  const [showLanguageMinimumError, setShowLanguageMinimumError] =
    useState<boolean>(false);
  const [showLanguageCheckedError, setShowLanguageCheckedError] =
    useState<boolean>(false);

  const onSubmit: SubmitHandler<IStep6FormInput> = (data) => {
    setShowLanguageMinimumError(false);
    setShowLanguageCheckedError(false);
    let languageError = false;
    console.log('DATA LANGUAGES:', data.languages);
    if (data.languages.length === 0) {
      setShowLanguageMinimumError(true);
      languageError = true;
    } else if (
      data.languages.filter((language) => language.main).length !== 1
    ) {
      setShowLanguageCheckedError(true);
      languageError = true;
    }
    if (!languageError) {
      data.step = 'languages_data';

      patchTvShowSeasonEpisode(data).then(function (result: any) {
        if (result && result.data) {
          dispatch(setCurrentTvShowSeasonEpisodeStep(7));
        }
      });
    }
  };
  const languages = useAppSelector(
    (state: RootState) => state.addMedia.languages,
  );

  console.log("THE ERRORS I'M LOOKING FOR:", errors);

  {
    isLoading &&
      Swal.fire({
        title: 'Loading',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
  }

  {
    isSuccess &&
      // Swal.fire({
      //   title: 'Logged in succesfully',
      //   text: 'Redirecting to home page',
      //   icon: 'success',
      //   timer: 1000,
      //   showConfirmButton: false,
      // });
      Swal.close();
  }

  const isLoginError = (error: any): error is ILoginFormError =>
    'status' in error;

  {
    isError &&
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
        confirmButtonColor: 'green',
        footer: isLoginError(error)
          ? (error as ILoginFormError).data?.Error ||
            (error as ILoginFormError).error
          : 'An error ocurred',
      });
  }

  return (
    <>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="media-h2">Languages</h2>
          {languageFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={3}>
                <FormGroup>
                  <Label for="language">Language</Label>
                  <Controller
                    // key={field.id}
                    name={`languages.${index}.language`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={languages}
                        placeholder="Select the episode's language"
                        error={errors.languages?.[index]}
                      />
                    )}
                  />

                  {errors.languages?.[index] && (
                    <div className="invalid-message">
                      {"The episode's language is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <FormGroup
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Label for="main">Main?</Label>
                  <Controller
                    // key={field.id}
                    name={`languages.${index}.main`}
                    control={control}
                    // rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type="checkbox"
                        style={{ marginTop: '10px' }}
                        // {...field}
                        // defaultChecked={field.value}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Label for="audioTrack">Audio Track?</Label>
                  <Controller
                    // key={field.id}
                    name={`languages.${index}.audioTrack`}
                    control={control}
                    // rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type="checkbox"
                        style={{ marginTop: '10px' }}
                        // {...field}
                        // // defaultChecked={field.value}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col md={1}>
                <FormGroup
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Label for="subtitle">Subtitle?</Label>
                  <Controller
                    // key={field.id}
                    name={`languages.${index}.subtitle`}
                    control={control}
                    // rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type="checkbox"
                        style={{ marginTop: '10px' }}
                        // {...field}
                        // // defaultChecked={field.value}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label for="subtitleSrt">Subtitle SRT</Label>
                  <FormFileInput
                    control={control}
                    name={`languages.${index}.subtitleSrt`}
                    id="subtitleSrt"
                    placeholder={"Select the episode's subtitle SRT"}
                    required={false}
                    error={errors.languages?.[index]}
                    accept=".srt"
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label for="subtitleVtt">Subtitle VTT</Label>
                  <FormFileInput
                    control={control}
                    name={`languages.${index}.subtitleVtt`}
                    id="subtitleVtt"
                    placeholder={"Select the episode's subtitle VTT"}
                    required={false}
                    error={errors.languages?.[index]}
                    accept=".vtt"
                  />
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    languageRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          {showLanguageMinimumError && (
            <div className="invalid-message">
              {'At least one language must be added'}
            </div>
          )}
          {showLanguageCheckedError && (
            <div className="invalid-message">
              {'One language must be selected as the main one'}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                languageAppend({
                  language: null,
                  main: false,
                  audioTrack: false,
                  subtitle: false,
                });
              }}
            >
              Add Language
            </Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
