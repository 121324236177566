export const getTitleFontSize = (title: string) => {
  if (title.length > 90) {
    return '1.2rem';
  } else if (title.length > 60) {
    return '3rem';
  } else if (title.length > 30) {
    return '4rem';
  } else {
    return '4.5rem';
  }
};

export const getOriginalTitleFontSize = (title: string) => {
  if (title.length > 90) {
    return '1.1rem';
  } else if (title.length > 60) {
    return '1.4rem';
  } else if (title.length > 30) {
    return '1.5rem';
  } else {
    return '1.6rem';
  }
};

export const getOverviewFontSize = (title: string) => {
  if (title.length > 900) {
    return '0.65rem';
  } else if (title.length > 600) {
    return '0.8rem';
  } else if (title.length > 300) {
    return '0.9rem';
  } else {
    return '1rem';
  }
};
