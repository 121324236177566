import { createSlice } from '@reduxjs/toolkit';
// import { extendedApiSlice } from '../services/movieServerApi'
export interface AddMediaState {
  selectedMediaType: string | null;
  currentMovieStep: number | null;
  selectedAddType: string | null;
  selectedDataSource: string | null;
  selectedItunesResult: any;
  selectedImdbResult: any;
  selectedItunesCountry: string | null;
  generalMovieData: any;
  secondaryMovieData: any;
  castCrewMovieData: any;
  imagesMovieData: any;
  languagesMovieData: any;
  chaptersMovieData: any;
  finalMovieData: any;
  studios: any[];
  ratings: any[];
  qualities: any[];
  formats: any[];
  sources: any[];
  genres: any[];
  countries: any[];
  tags: any[];
  collections: any[];
  languages: any[];
  accessLevels: any[];
  newMovieId: string;
}

const initialState: AddMediaState = {
  selectedMediaType: null,
  currentMovieStep: 1,
  selectedAddType: null,
  selectedDataSource: null,
  selectedItunesResult: null,
  selectedImdbResult: null,
  selectedItunesCountry: null,
  generalMovieData: null,
  secondaryMovieData: null,
  castCrewMovieData: null,
  imagesMovieData: null,
  languagesMovieData: null,
  chaptersMovieData: { hasNamedChapters: false },
  finalMovieData: { editingCompleted: false, comments: '' },
  studios: [],
  ratings: [],
  qualities: [],
  formats: [],
  sources: [],
  genres: [],
  countries: [],
  tags: [],
  collections: [],
  languages: [],
  accessLevels: [],
  newMovieId: '',
};

export const addMediaSlice = createSlice({
  name: 'addMedia',
  initialState,
  reducers: {
    setSelectedMediaType: (state, { payload }) => {
      state.selectedMediaType = payload;
    },
    setSelectedItunesAndImdbResults: (state, { payload }) => {
      state.selectedItunesResult = payload.selectedItunesResult;
      state.selectedImdbResult = payload.selectedImdbResult;
      state.selectedItunesCountry = payload.selectedItunesCountry;
    },
    setCurrentMovieStep: (state, { payload }) => {
      state.currentMovieStep = payload;
    },
    setSelectedAddType: (state, { payload }) => {
      state.selectedAddType = payload;
    },
    setSelectedDataSource: (state, { payload }) => {
      state.selectedDataSource = payload;
    },
    setMovieData: (state, { payload }) => {
      state.generalMovieData = payload.generalMovieData;
      state.secondaryMovieData = payload.secondaryMovieData;
      state.castCrewMovieData = payload.castCrewMovieData;
      state.languagesMovieData = payload.languagesMovieData;
      state.studios = payload.studios;
      state.ratings = payload.ratings;
      state.qualities = payload.qualities;
      state.formats = payload.formats;
      state.sources = payload.sources;

      state.genres = payload.genres;
      state.countries = payload.countries;
      state.tags = payload.tags;
      state.collections = payload.collections;

      state.languages = payload.languages;
      state.accessLevels = payload.accessLevels;
    },
    setGeneralMovieData: (state, { payload }) => {
      state.generalMovieData = payload;
    },
    setSecondaryMovieData: (state, { payload }) => {
      state.secondaryMovieData = payload;
    },
    setCastCrewMovieData: (state, { payload }) => {
      state.castCrewMovieData = payload;
    },
    setImagesMovieData: (state, { payload }) => {
      state.imagesMovieData = payload;
    },
    setLanguagesMovieData: (state, { payload }) => {
      state.languagesMovieData = payload;
    },
    setChaptersMovieData: (state, { payload }) => {
      state.chaptersMovieData = payload;
    },
    setFinalMovieData: (state, { payload }) => {
      state.finalMovieData = payload;
    },
    setNewMovieId: (state, { payload }) => {
      state.newMovieId = payload;
    },
  },
});

export const {
  setSelectedMediaType,
  setSelectedItunesAndImdbResults,
  setCurrentMovieStep,
  setSelectedAddType,
  setSelectedDataSource,
  setMovieData,
  setGeneralMovieData,
  setSecondaryMovieData,
  setCastCrewMovieData,
  setImagesMovieData,
  setLanguagesMovieData,
  setChaptersMovieData,
  setFinalMovieData,
  setNewMovieId,
} = addMediaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export default addMediaSlice.reducer;
