// import { useState } from 'react';
import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button as ButtonRs,
} from 'reactstrap';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { IMovieStep5FormInput } from '../../../../interfaces/forms/IMovieStep5Form';
import {
  setCurrentMovieStep,
  setSecondaryMovieData,
} from '../../../../app/slices/addMediaSlice';

export const MovieStep5 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);
  const dispatch = useAppDispatch();
  const secondaryMovieData = useAppSelector(
    (state: RootState) => state.addMedia.secondaryMovieData,
  );

  const {
    control,
    handleSubmit,
    // register,
    // reset,
    formState: { errors },
  } = useForm<IMovieStep5FormInput>({
    defaultValues: secondaryMovieData,

    //   defaultValues: useMemo(() => {
    //     return data?.movieData;
    // }, [data])
  });

  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
  //   control, name: "genresFieldArray",
  // });

  const {
    fields: genreFields,
    append: genreAppend,
    remove: genreRemove,
  } = useFieldArray({ control, name: 'genres', rules: { minLength: 1 } });

  const {
    fields: countryFields,
    append: countryAppend,
    remove: countryRemove,
  } = useFieldArray({ control, name: 'countries', rules: { minLength: 1 } });

  const {
    fields: tagFields,
    append: tagAppend,
    remove: tagRemove,
  } = useFieldArray({ control, name: 'tags', rules: { minLength: 1 } });

  const {
    fields: collectionFields,
    append: collectionAppend,
    remove: collectionRemove,
  } = useFieldArray({ control, name: 'collections', rules: { minLength: 1 } });

  const [showGenreMinimumError, setShowGenreMinimumError] =
    useState<boolean>(false);
  const [showGenreCheckedError, setShowGenreCheckedError] =
    useState<boolean>(false);

  const [showCountryMinimumError, setShowCountryMinimumError] =
    useState<boolean>(false);
  const [showCountryCheckedError, setShowCountryCheckedError] =
    useState<boolean>(false);

  const onSubmit: SubmitHandler<IMovieStep5FormInput> = (data) => {
    setShowGenreMinimumError(false);
    setShowGenreCheckedError(false);
    setShowCountryMinimumError(false);
    setShowCountryCheckedError(false);
    let genreError = false;
    let countryError = false;
    if (data.genres.length === 0) {
      setShowGenreMinimumError(true);
      genreError = true;
    } else if (data.genres.filter((genre) => genre.main).length !== 1) {
      setShowGenreCheckedError(true);
      genreError = true;
    }
    if (data.countries.length === 0) {
      setShowCountryMinimumError(true);
      countryError = true;
    } else if (data.countries.filter((country) => country.main).length !== 1) {
      setShowCountryCheckedError(true);
      countryError = true;
    }
    if (!genreError && !countryError) {
      console.log('DATA MOVIE STEP 3:', data);
      dispatch(setSecondaryMovieData(data));
      dispatch(setCurrentMovieStep(6));
    }
  };

  const genres = useAppSelector((state: RootState) => state.addMedia.genres);
  const countries = useAppSelector(
    (state: RootState) => state.addMedia.countries,
  );
  const tags = useAppSelector((state: RootState) => state.addMedia.tags);
  const collections = useAppSelector(
    (state: RootState) => state.addMedia.collections,
  );
  console.log("THE ERRORS I'M LOOKING FOR:", errors);

  return (
    <>
      <div className="big-form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="media-h2">Genres</h2>
          {genreFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={10}>
                <FormGroup>
                  <Label for="genre">Genre</Label>
                  <Controller
                    // key={field.id}
                    name={`genres.${index}.genre`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={genres}
                        placeholder="Select the movie's genre"
                        error={errors.genres?.[index]}
                      />
                    )}
                  />

                  {errors.genres?.[index] && (
                    <div className="invalid-message">
                      {"The movie's genre is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <FormGroup
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Label for="main">Main?</Label>
                  <Controller
                    // key={field.id}
                    name={`genres.${index}.main`}
                    control={control}
                    // rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type="checkbox"
                        style={{ marginTop: '10px' }}
                        // {...field}
                        // defaultChecked={field.value}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    genreRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          {showGenreMinimumError && (
            <div className="invalid-message">
              {'At least one genre must be added'}
            </div>
          )}
          {showGenreCheckedError && (
            <div className="invalid-message">
              {'One genre must be selected as the main one'}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                genreAppend({ genre: null, main: false });
              }}
            >
              Add Genre
            </Button>
          </div>

          <h2 className="media-h2">Countries</h2>
          {countryFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={10}>
                <FormGroup>
                  <Label for="country">Country</Label>
                  <Controller
                    // key={field.id}
                    name={`countries.${index}.country`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={countries}
                        placeholder="Select the movie's country"
                        error={errors.countries?.[index]}
                      />
                    )}
                  />

                  {errors.countries?.[index] && (
                    <div className="invalid-message">
                      {"The movie's country is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <FormGroup
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Label for="main">Main?</Label>
                  <Controller
                    // key={field.id}
                    name={`countries.${index}.main`}
                    control={control}
                    // rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        type="checkbox"
                        style={{ marginTop: '10px' }}
                        // {...field}
                        // defaultChecked={field.value}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    countryRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          {showCountryMinimumError && (
            <div className="invalid-message">
              {'At least one country must be added'}
            </div>
          )}
          {showCountryCheckedError && (
            <div className="invalid-message">
              {'One country must be selected as the main one'}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                countryAppend({ country: null, main: false });
              }}
            >
              Add Country
            </Button>
          </div>

          <h2 className="media-h2">Tags</h2>
          {tagFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={11}>
                <FormGroup>
                  <Label for="tag">Tag</Label>
                  <Controller
                    // key={field.id}
                    name={`tags.${index}`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={tags}
                        placeholder="Select the movie's tag"
                        error={errors.tags?.[index]}
                      />
                    )}
                  />

                  {errors.tags?.[index] && (
                    <div className="invalid-message">
                      {"The movie's tag is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    tagRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                tagAppend({ id: null, label: null });
              }}
            >
              Add Tag
            </Button>
          </div>

          <h2 className="media-h2">Collections</h2>
          {collectionFields.map((field, index) => (
            <Row key={field.id}>
              <Col md={11}>
                <FormGroup>
                  <Label for="collection">Collection</Label>
                  <Controller
                    // key={field.id}
                    name={`collections.${index}`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FormSelect
                        field={field}
                        options={collections}
                        placeholder="Select the movie's collection"
                        error={errors.collections?.[index]}
                      />
                    )}
                  />

                  {errors.collections?.[index] && (
                    <div className="invalid-message">
                      {"The movie's collection is required"}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={1}>
                <ButtonRs
                  type="button"
                  color="danger"
                  onClick={() => {
                    collectionRemove(index);
                  }}
                >
                  X
                </ButtonRs>
              </Col>
            </Row>
          ))}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="button"
              onClick={() => {
                collectionAppend({ id: null, label: null });
              }}
            >
              Add Collection
            </Button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </>
  );
};
