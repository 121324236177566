import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { logout, setAccessToken } from '../slices/authenticationSlice';
// import { setMoviePageStatus } from '../slices/navigationSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: `${import.meta.env.VITE_API_URL}/api/`,
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const userAccessToken = (getState() as RootState).authentication
      .userAccessToken;
    if (userAccessToken) {
      // include token in req header
      headers.set('authorization', `Bearer ${userAccessToken}`);
      return headers;
    }
  },
});
const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result: any = await baseQuery(args, api, extraOptions);
  console.log('ARGS:', args);
  console.log('API:', api);
  console.log('EXTRA OPTIONS:', extraOptions);
  console.log('API RESULT:', result);
  if (result?.error?.status === 401) {
    // send refresh token to get new access token
    const userRefreshToken = api.getState().authentication.userRefreshToken;
    const refreshResult = await baseQuery(
      {
        url: 'authentication/token/refresh/',
        method: 'POST',
        body: { refresh: userRefreshToken },
      },
      api,
      extraOptions,
    );
    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setAccessToken(refreshResult.data));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }
  // else if (result?.error?.status === 'FETCH_ERROR') {
  //   console.log('HERE THE ERROR 2:', result?.error);
  //   throw { error: { status: 404, message: 'gg' } };
  //   //INVESTIGATE THIS
  // }

  // if (result?.data?.totalPages) {
  //   if ('test' in result) {
  //     console.log('result:', result);
  //     api.dispatch(
  //       setMoviePageStatus({
  //         currentPage: result?.data?.currentPage,
  //         totalPages: result?.data?.totalPages,
  //       }),
  //     );
  //   }
  // }
  console.log('GOT HERE');
  return result;
};

export const mediaManagerServerApi = createApi({
  reducerPath: 'mediaManagerServerApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Movie',
    // 'Movies',
    'TVShow',
    // 'TVShows',
    'TVShowSeason',
    // 'TVShowSeasons',
    'TVShowSeasonEpisode',
    // 'TVShowSeasonEpisodes',
    'Autograph',
    // 'Autographs',
  ],
  endpoints: () => ({
    // getTokens: builder.mutation({
    //   query: (data) => ({
    //     url: `authentication/token/`,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),
    // refreshTokens: builder.mutation({
    //   query: (data) => ({
    //     url: `authentication/token/refresh/`,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),
  }),
});

// export const { useGetTokensMutation, useRefreshTokensMutation } = mediaManagerServerApi;
