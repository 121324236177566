import { Button, FormSelect } from '../../../../components';
import '../../AddMedia.scss';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  setCurrentMovieStep,
  setGeneralMovieData,
} from '../../../../app/slices/addMediaSlice';
import { RootState } from '../../../../app/store';
import { IMovieStep4FormInput } from '../../../../interfaces/forms/IMovieStep4Form';

// export const MovieForm = ({ data }: any) => {
export const MovieForm = () => {
  const dispatch = useAppDispatch();

  const generalMovieData = useAppSelector(
    (state: RootState) => state.addMedia.generalMovieData,
  );

  const studios = useAppSelector((state: RootState) => state.addMedia.studios);
  const ratings = useAppSelector((state: RootState) => state.addMedia.ratings);
  const qualities = useAppSelector(
    (state: RootState) => state.addMedia.qualities,
  );
  const formats = useAppSelector((state: RootState) => state.addMedia.formats);
  const sources = useAppSelector((state: RootState) => state.addMedia.sources);

  const selectedDataSource = useAppSelector(
    (state: RootState) => state.addMedia.selectedDataSource,
  );

  const {
    control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm<IMovieStep4FormInput>({
    // defaultValues: data.movieData,
    defaultValues: generalMovieData,

    //   defaultValues: useMemo(() => {
    //     return data?.movieData;
    // }, [data])
  });

  const onSubmit: SubmitHandler<IMovieStep4FormInput> = (formdata) => {
    // login(data).then(function (result: any) {
    //   if (result && result.data) {
    dispatch(setGeneralMovieData(formdata));
    dispatch(setCurrentMovieStep(5));

    //   }
    // })
    console.log('DATA MOVIE STEP 4:', formdata);
  };

  return (
    <div className="big-form-container">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="title">Movie Title *</Label>
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="title"
                    placeholder="Enter the movie's title"
                    type="text"
                    invalid={!!errors.title}
                    {...field}
                  />
                )}
              />
              <FormFeedback>{"The movie's title is required"}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="originalTitle">Original Title</Label>
              <Controller
                name="originalTitle"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="originalTitle"
                    placeholder="Enter the movie's original title"
                    type="text"
                    invalid={!!errors.originalTitle}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="itunesTitle">iTunes Title</Label>
              <Controller
                name="itunesTitle"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="itunesTitle"
                    placeholder="Enter the movie's iTunes title"
                    type="text"
                    invalid={!!errors.itunesTitle}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="shortDescription">Short Description *</Label>
              <Controller
                name="shortDescription"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="shortDescription"
                    placeholder="Enter the movie's short description"
                    type="textarea"
                    invalid={!!errors.shortDescription}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The movie's short description is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="description">Description *</Label>
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="description"
                    placeholder="Enter the movie's description"
                    type="textarea"
                    invalid={!!errors.description}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The movie's description is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="releaseDate">Release Date *</Label>
              <Controller
                name="releaseDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="releaseDate"
                    placeholder="Enter the movie's release date"
                    type="text"
                    invalid={!!errors.releaseDate}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {"The movie's release date is required"}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="studio">Studio</Label>
              <Controller
                name="studio"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={studios}
                    placeholder="Select the movie's studio"
                    error={errors.studio}
                  />
                )}
              />
              {/* {errors.studio && (
                <div className="invalid-message">
                  {"The movie's studio is required"}
                </div>
              )} */}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="rating">Rating *</Label>
              <Controller
                name="rating"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={ratings}
                    placeholder="Select the movie's rating"
                    error={errors.rating}
                  />
                )}
              />
              {errors.rating && (
                <div className="invalid-message">
                  {"The movie's rating is required"}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="copyright">Copyright</Label>
              <Controller
                name="copyright"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="copyright"
                    placeholder="Enter the movie's copyright"
                    type="text"
                    invalid={!!errors.copyright}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="quality">Quality *</Label>
              <Controller
                name="quality"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={qualities}
                    placeholder="Select the movie's quality"
                    error={errors.quality}
                  />
                )}
              />
              {errors.quality && (
                <div className="invalid-message">
                  {"The movie's quality is required"}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="format">Format *</Label>
              <Controller
                name="format"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={formats}
                    placeholder="Select the movie's format"
                    error={errors.format}
                  />
                )}
              />
              {errors.format && (
                <div className="invalid-message">
                  {"The movie's format is required"}
                </div>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="source">Source *</Label>
              <Controller
                name="source"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <FormSelect
                    field={field}
                    options={sources}
                    placeholder="Select the movie's source"
                    error={errors.source}
                  />
                )}
              />
              {errors.source && (
                <div className="invalid-message">
                  {"The movie's source is required"}
                </div>
              )}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="sourceLink">Source Link</Label>
              <Controller
                name="sourceLink"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="sourceLink"
                    placeholder="Enter the movie's source link"
                    type="text"
                    invalid={!!errors.sourceLink}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          {selectedDataSource === 'itunesAndImdb' && (
            <Col md={4}>
              <FormGroup>
                <Label for="itunesId">iTunes ID *</Label>
                <Controller
                  name="itunesId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Input
                      id="itunesId"
                      placeholder="Enter the movie's iTunes ID"
                      type="text"
                      invalid={!!errors.itunesId}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {"The movie's iTunes ID is required"}
                </FormFeedback>
              </FormGroup>
            </Col>
          )}
          <Col md={selectedDataSource === 'itunesAndImdb' ? 4 : 6}>
            <FormGroup>
              <Label for="imdbId">IMDb ID *</Label>
              <Controller
                name="imdbId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    id="imdbId"
                    placeholder="Enter the movie's IMDb ID"
                    type="text"
                    invalid={!!errors.imdbId}
                    {...field}
                  />
                )}
              />
              <FormFeedback>{"The movie's IMDb ID is required"}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={selectedDataSource === 'itunesAndImdb' ? 4 : 6}>
            <FormGroup>
              <Label for="imdbRating">IMDb Rating</Label>
              <Controller
                name="imdbRating"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="imdbRating"
                    placeholder="Enter the movie's IMDb rating"
                    type="text"
                    invalid={!!errors.imdbRating}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="trailerUrl">Trailer URL</Label>
              <Controller
                name="trailerUrl"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <Input
                    id="trailerUrl"
                    placeholder="Enter the movie's trailer URL"
                    type="text"
                    invalid={!!errors.trailerUrl}
                    {...field}
                  />
                )}
              />
            </FormGroup>
          </Col>
        </Row>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button type="submit">SearchY</Button>
        </div>
      </Form>
    </div>
  );
};
