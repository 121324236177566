// import { useEffect } from 'react';
import { useParams } from 'react-router';
// import { useDispatch } from 'react-redux';
import '../Detail/Detail.scss';

import {
  TVShowList,
  TVShowSeasonList,
  LoadingSpinner,
  Button,
} from '../../components';
import { Link } from 'react-router-dom';
import { useGetTVShowQuery } from '../../app/services/tvShowServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getOriginalTitleFontSize,
  getOverviewFontSize,
  getTitleFontSize,
} from '../../shared/utils';

export const TVShowDetail = () => {
  const { category, id } = useParams();
  const {
    data: tvShowItem = {},
    isLoading,
    // isFetching,
    isError,
    error,
  } = useGetTVShowQuery(id);

  // const dispatch = useDispatch();

  // const cover = tvShowItem.cover;
  const background = tvShowItem.background
    ? tvShowItem.background
    : tvShowItem.cover
      ? tvShowItem.cover
      : tvShowItem.image;

  const renderSimilarItems = () => {
    return (
      <TVShowList category={category} type="similar" id={tvShowItem.id} />
    );
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   //   dispatch(fetchtvShowItem(category, id))
  // }, [dispatch, category, id]);

  return (
    <div>
      {isLoading ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      tvShowItem.title || tvShowItem.name,
                    ),
                  }}
                >
                  {tvShowItem.title || tvShowItem.name}
                </h1>
                {tvShowItem.originalTitle ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(
                        tvShowItem.originalTitle,
                      ),
                    }}
                  >
                    Original Title: {tvShowItem.originalTitle}
                  </h3>
                ) : null}
                <div className="genres">
                  <span className="badge genres__item">{tvShowItem.year}</span>
                  <span className="badge genres__item">
                    {tvShowItem.genres && tvShowItem.genres[0].name}
                  </span>
                  <span className="badge genres__item">
                    {tvShowItem.rating && tvShowItem.rating.name}
                  </span>
                  <span className="badge genres__item">
                    IMDB Rating: {tvShowItem.imdbRating}
                  </span>
                </div>
                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize(tvShowItem.description),
                  }}
                >
                  {tvShowItem.description}
                </p>
              </div>

              <div className="movie-content__poster">
                <a href={tvShowItem.cover} target="_blank" rel="noreferrer">
                  <img
                    className="movie-content__poster__img_tv_show"
                    src={tvShowItem.cover}
                    alt={tvShowItem.title || tvShowItem.name}
                  ></img>
                </a>
              </div>
            </div>
          </div>

          <div className="movie-content-description">
            {tvShowItem.originalTitle ? (
              <h3
                className="original-title"
                style={{
                  fontSize: getOriginalTitleFontSize(tvShowItem.originalTitle),
                }}
              >
                Original Title: {tvShowItem.originalTitle}
              </h3>
            ) : null}
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {tvShowItem.description}
            </p>
          </div>

          <div className="detail-container seasons-list">
            <div className="media-section">
              <div className="media-section__header">
                <h2 className="subtitle">Seasons</h2>
              </div>
              <TVShowSeasonList
                tvShowID={tvShowItem.id}
                seasons={tvShowItem.seasons}
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Genres'}</h2>
              </div>
              <div className="genres">
                {tvShowItem.genres &&
                  tvShowItem.genres.map((genre: any, i: number) => (
                    <span key={i} className="badge genres__item">
                      {genre.label}
                    </span>
                  ))}
              </div>

              <div className="media-section__header">
                <h2 className="subtitle">{'Countries'}</h2>
              </div>
              <div className="genres">
                {tvShowItem.countries &&
                  tvShowItem.countries.map((country: any, i: number) => (
                    <span key={i} className="badge genres__item">
                      {country.label}
                    </span>
                  ))}
              </div>
            </div>
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Tags'}</h2>
              </div>
              <div className="genres">
                {tvShowItem.tags &&
                  tvShowItem.tags.map((tag: any, i: number) => (
                    <Link
                      className="media-a"
                      to={'/tv-shows/search/tag/' + tag.label}
                      key={i}
                    >
                      <span key={i} className="badge genres__item">
                        {tag.label}
                      </span>
                    </Link>
                  ))}
              </div>
              <div className="media-section__header">
                <h2 className="subtitle">{'Collections'}</h2>
              </div>
              <div className="genres">
                {tvShowItem.collections &&
                  tvShowItem.collections.map((collection: any, i: number) => (
                    <Link
                      className="media-a"
                      to={'/tv-shows/search/collection/' + collection.label}
                      key={i}
                    >
                      <span key={i} className="badge genres__item">
                        {collection.label}
                      </span>
                    </Link>
                  ))}
              </div>
            </div>
          </div>

          <div className="detail-container">
            <div className="media-section mt-3">
              <div className="media-section__header">
                <h2 className="subtitle">Similar</h2>
              </div>
              {renderSimilarItems()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
