import { useEffect, useState } from 'react';

import './MediaGrid.scss';

import { Button, Input } from '../../components';

// import tmdbApi, { category, movieType, tvType } from '../../api/tmdbApi';

export const MediaSearch = (props: any) => {
  const [searchKeyword, setSearchKeyword] = useState(
    props.currentSearchParams.keyword,
  );
  console.log('PICABU:', props.currentSearchParams);

  const serializeFormQuery = (keyword: string) => {
    const currentSearchParams = props.currentSearchParams;
    // currentSearchParams.keyword = keyword
    // currentSearchParams.page = 1
    const new_obj = { ...currentSearchParams, keyword: keyword, page: 1 };
    return new_obj;
  };

  const searchItems = () => {
    // console.log("LOG", title)
    // if (keyword.trim().length > 0) {
    const params = serializeFormQuery(searchKeyword);
    props.setCurrentSearchParamsFunction(params);

    const new_obj = { ...params };
    delete new_obj.page;
    props.setParamsFunction(new_obj);
    // props.setKeywordFunction(searchKeyword)
    // dispatch(fetchFilteredMediaGridItems(props.category, 1, title))
    // }
  };

  useEffect(() => {
    // console.log('EFFECT 1');
    const enterEvent = (e: any) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        searchItems();
      }
    };
    // console.log('EFFECT 2');
    document.addEventListener('keyup', enterEvent);
    return () => {
      // console.log('EFFECT 3');
      document.removeEventListener('keyup', enterEvent);
    };
    // eslint-disable-next-line
  }, [searchKeyword]);

  return (
    <>
      <div className="media-search">
        <Input
          type="text"
          placeholder="Enter keyword"
          value={searchKeyword}
          onChange={(e: any) => setSearchKeyword(e.target.value)}
        />
        <Button className="small" onClick={() => searchItems()}>
          Search
        </Button>
      </div>
    </>
  );
};
