import './SelectableMovieCard.scss';

// import { category } from '../../api/tmdbApi';
// import apiConfig from '../../api/apiConfig';
// import { useDispatch, useSelector } from 'react-redux'

export const SelectableMovieCard = (props: any) => {
  // const item  = props.item;

  // const bg = apiConfig.w500Image(item.poster_path || item.backdrop_path);
  // const background = item.poster;
  // const dispatch = useDispatch()
  console.log('PROPS:', props);

  return (
    <>
      <div
        className={`selectable-movie-card ${props.selected ? 'selected' : null}`}
        style={{ backgroundImage: `url(${props.item.cover})` }}
        // onClick
      ></div>
      <h3 className="media-h3">{props.item.title}</h3>
    </>
  );
};
