import './Footer.scss';
import { Link } from 'react-router-dom';
import bg from '../../assets/sample_background_image_2.jpg';
import logo from '../../assets/media-manager-logo.png';

export const Footer = () => {
  return (
    <div className="media-footer" style={{ backgroundImage: `url(${bg})` }}>
      <div className="media-footer__content detail-container">
        <div className="media-footer__content__logo">
          <div className="media-logo-footer">
            <img src={logo} alt="media-manager-logo" />
            <Link className="media-a" to="/">
              MediaManager
            </Link>
          </div>
        </div>
        {/* <div className="media-footer__content__menus">
          <div className="media-footer__content__menu">
            <Link className="media-a" to="/">
              Home
            </Link>
          </div>
          <div className="media-footer__content__menu">
            <Link className="media-a" to="/">
              Live
            </Link>
          </div>
          <div className="media-footer__content__menu">
            <Link className="media-a" to="/">
              You must watch
            </Link>
          </div>
        </div> */}
      </div>
    </div>
  );
};
