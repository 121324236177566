import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// import { movieServerApi } from './services/movieServerApi';
// import { tvShowServerApi } from './services/tvShowServerApi';
// import { autographServerApi } from './services/autographServerApi';
// import { authenticationServerApi } from './services/authenticationServerApi';
import counterReducer from './features/counter/counterSlice';
import authenticationReducer from './slices/authenticationSlice';
import navigationReducer from './slices/navigationSlice';
import addMediaReducer from './slices/addMediaSlice';
import addTvShowReducer from './slices/addTvShowSlice';
import addTvShowSeasonReducer from './slices/addTvShowSeasonSlice';
import addTvShowSeasonEpisodeReducer from './slices/addTvShowSeasonEpisodeSlice';
import { mediaManagerServerApi } from './services/mediaManagerServerApi';

export const store = configureStore({
  reducer: {
    // [movieServerApi.reducerPath]: movieServerApi.reducer,
    // [tvShowServerApi.reducerPath]: tvShowServerApi.reducer,
    // [autographServerApi.reducerPath]: autographServerApi.reducer,
    // [authenticationServerApi.reducerPath]: authenticationServerApi.reducer,
    [mediaManagerServerApi.reducerPath]: mediaManagerServerApi.reducer,
    counter: counterReducer,
    authentication: authenticationReducer,
    navigation: navigationReducer,
    addMedia: addMediaReducer,
    addTvShow: addTvShowReducer,
    addTvShowSeason: addTvShowSeasonReducer,
    addTvShowSeasonEpisode: addTvShowSeasonEpisodeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      // .concat(movieServerApi.middleware)
      // .concat(tvShowServerApi.middleware)
      // .concat(autographServerApi.middleware)
      // .concat(authenticationServerApi.middleware)
      .concat(mediaManagerServerApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
