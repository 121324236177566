// import { useEffect, useState } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
import '../Detail/Detail.scss';
import {
  Button,
  OutlineButton,
  MovieList,
  ArtistList,
  MediaModal,
  TrailerModal,
  LoadingSpinner,
} from '../../components';
import copyIcon from '../../assets/copy-icon.svg';
import backgroundIcon from '../../assets/background-icon.svg';
import { Link } from 'react-router-dom';
import { useGetMovieQuery } from '../../app/services/movieServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getOverviewFontSize,
  getOriginalTitleFontSize,
  getTitleFontSize,
} from '../../shared/utils';

export const MovieDetail = () => {
  const { category, id } = useParams();
  const {
    data: movieItem = {},
    isLoading,
    // isFetching,
    isError,
    error,
  } = useGetMovieQuery(id);
  console.log('ERROR:', error);
  const background = movieItem.background
    ? movieItem.background
    : movieItem.cover
      ? movieItem.cover
      : movieItem.image;
  const renderSimilarItems = () => {
    return <MovieList category={category} type="similar" id={movieItem.id} />;
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   // dispatch(fetchMediaDetailItem(category, id))
  // }, [category, id]);

  const copyToClipboard = () => {
    const TempText = document.createElement('input');
    // TempText.value = movieLink;
    document.body.appendChild(TempText);
    TempText.select();
    document.execCommand('copy');
    document.body.removeChild(TempText);
    alert('Movie link copied to clipboard');
  };

  const [openMovieModal, setOpenMovieModal] = useState(false);
  const toggleMovieModal = () => setOpenMovieModal(!openMovieModal);

  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  return (
    <div>
      {isLoading ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <div
            className="banner"
            style={{ backgroundImage: `url(${background})` }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      movieItem.title || movieItem.name,
                    ),
                  }}
                >
                  {movieItem.title || movieItem.name}
                </h1>
                {movieItem.originalTitle ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(
                        movieItem.originalTitle,
                      ),
                    }}
                  >
                    Original Title: {movieItem.originalTitle}
                  </h3>
                ) : null}

                <div className="genres">
                  <span className="badge genres__item">
                    {movieItem.releaseDate}
                  </span>
                  <span className="badge genres__item">
                    {movieItem.runtime}
                  </span>
                  <span className="badge genres__item">
                    {movieItem.genres && movieItem.genres[0].label}
                  </span>
                  <span className="badge genres__item">
                    {movieItem.rating && movieItem.rating.label}
                  </span>

                  <span className="badge genres__item">
                    {movieItem.quality && movieItem.quality.label}
                  </span>
                  <span className="badge genres__item">
                    {movieItem.movieLanguages &&
                      movieItem.movieLanguages[0].language.label}
                  </span>
                  {/* <span className="badge genres__item">
                    {movieItem.movieLanguages &&
                      movieItem.movieLanguages[0].language.label}
                  </span> */}
                </div>

                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize(movieItem.description),
                  }}
                >
                  {movieItem.description}
                </p>

                <div className="btns">
                  <Button onClick={toggleMovieModal}>Watch now</Button>
                  <OutlineButton onClick={toggleTrailerModal}>
                    Watch trailer
                  </OutlineButton>
                </div>
              </div>

              <div className="movie-content__poster">
                <a href={movieItem.cover} target="_blank" rel="noreferrer">
                  <img
                    className="movie-content__poster__img_movie"
                    src={movieItem.cover}
                    alt={movieItem.title || movieItem.name}
                    // role="button"
                    // tabIndex={0}
                    // onKeyDown={() => window.open(movieItem.cover, '_blank')}
                    // onClick={() => window.open(movieItem.cover, '_blank')}
                  ></img>
                </a>
              </div>
            </div>
          </div>

          <div className="movie-content-description">
            {movieItem.originalTitle ? (
              <h3
                className="original-title"
                style={{
                  fontSize: getOriginalTitleFontSize(movieItem.originalTitle),
                }}
              >
                Original Title: {movieItem.originalTitle}
              </h3>
            ) : null}
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {movieItem.description}
            </p>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Directors'}</h2>
              </div>
              <ArtistList
                artists={movieItem.movieDirectors}
                category="director"
              />
            </div>
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Cast'}</h2>
              </div>
              <ArtistList artists={movieItem.movieActors} category="actor" />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Producers'}</h2>
              </div>
              <ArtistList
                artists={movieItem.movieProducers}
                category="producer"
              />
            </div>
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Screenwriters'}</h2>
              </div>
              <ArtistList
                artists={movieItem.movieScreenwriters}
                category="screenwriter"
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Genres'}</h2>
              </div>
              <div className="genres">
                {movieItem.genres &&
                  movieItem.genres.map((genre: any, i: number) => (
                    <span key={i} className="badge genres__item">
                      {genre.label}
                    </span>
                  ))}
              </div>
              <div className="media-section__header">
                <h2 className="subtitle">{'Languages'}</h2>
              </div>
              <div className="genres">
                {movieItem.movieLanguages &&
                  movieItem.movieLanguages.map(
                    (movieLanguage: any, i: number) =>
                      movieLanguage.audioTrack && (
                        <span key={i} className="badge genres__item">
                          {movieLanguage.language.label}
                        </span>
                      ),
                  )}
              </div>

              <div className="media-section__header">
                <h2 className="subtitle">{'Subtitles'}</h2>
              </div>
              <div className="genres">
                {movieItem.movieLanguages &&
                  movieItem.movieLanguages.map(
                    (movieLanguage: any, i: number) =>
                      movieLanguage.subtitle && (
                        <span key={i} className="badge genres__item">
                          {movieLanguage.language.label}
                        </span>
                      ),
                  )}
              </div>

              <div className="media-section__header">
                <h2 className="subtitle">{'Countries'}</h2>
              </div>
              <div className="genres">
                {movieItem.movieCountries &&
                  movieItem.movieCountries.map(
                    (movieCountry: any, i: number) => (
                      <span key={i} className="badge genres__item">
                        {movieCountry.country.label}
                      </span>
                    ),
                  )}
              </div>
            </div>

            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>
              <div className="genres">
                <Link
                  className="media-a"
                  to={'/movies/?studio=' + movieItem?.studio?.label}
                >
                  <span className="badge genres__item">
                    Studio: {movieItem.studio && movieItem.studio.label}
                  </span>
                </Link>
                <span className="badge genres__item">
                  IMDB Rating: {movieItem.imdbRating}
                </span>
              </div>
              <div className="genres">
                <p className="overview">Copyright: {movieItem.copyright}</p>
              </div>

              <div className="genres">
                <OutlineButton
                  onClick={() => copyToClipboard()}
                  className="secondary-button"
                >
                  <img src={copyIcon} width="25" height="25" alt="" />
                </OutlineButton>
                <OutlineButton
                  onClick={() => window.open(background, '_blank')}
                  className="secondary-button"
                >
                  <img src={backgroundIcon} width="25" height="25" alt="" />
                </OutlineButton>
              </div>

              <div className="media-section__header">
                <h2 className="subtitle">{'Tags'}</h2>
              </div>
              <div className="genres">
                {movieItem.tags &&
                  movieItem.tags.map((tag: any, i: number) => (
                    <Link
                      className="media-a"
                      to={'/movies/?tag=' + tag.label}
                      key={i}
                    >
                      <span key={i} className="badge genres__item">
                        {tag.label}
                      </span>
                    </Link>
                  ))}
              </div>
              <div className="media-section__header">
                <h2 className="subtitle">{'Collections'}</h2>
              </div>
              <div className="genres">
                {movieItem.collections &&
                  movieItem.collections.map((collection: any, i: number) => (
                    <Link
                      className="media-a"
                      to={'/movies/?collection=' + collection.label}
                      key={i}
                    >
                      <span key={i} className="badge genres__item">
                        {collection.label}
                      </span>
                    </Link>
                  ))}
              </div>
            </div>
          </div>

          <div className="detail-container">
            <div className="media-section mt-3">
              <div className="media-section__header">
                <h2 className="subtitle">Similar</h2>
              </div>
              {renderSimilarItems()}
            </div>
          </div>

          <TrailerModal
            isOpen={openTrailerModal}
            toggle={toggleTrailerModal}
            item={movieItem}
          />
          <MediaModal
            isOpen={openMovieModal}
            toggle={toggleMovieModal}
            item={movieItem}
            category={'movie'}
          />
        </>
      )}
    </div>
  );
};
