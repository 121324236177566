/* eslint-disable import/no-unresolved */
import SwiperCore from 'swiper';
import { Autoplay, Keyboard, Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import './MediaSlide.scss';
import { MediaSlideItem } from './MediaSlideItem';
import { useGetMoviesQuery } from '../../app/services/movieServerApi';
import { LoadingSpinner } from '..';
import errorIcon from '../../assets/error.svg';
import noDataIcon from '../../assets/nodata.svg';

export const MediaSlide = () => {
  SwiperCore.use([Autoplay, Keyboard, Mousewheel]);

  const {
    data = {},
    isLoading,
    // isSuccess,
    // isFetching,
    isError,
    error,
  } = useGetMoviesQuery({ page: 1 });
  console.log('ERROR HERE:', error);

  return (
    <div className="media-slide">
      {isLoading ? (
        <div className="media-slide-no-item">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item">
          <img src={errorIcon} alt="error-icon" />
          <p>{error?.error}</p>
        </div>
      ) : data.results.length === 0 ? (
        <div className="media-slide-no-item">
          <img src={noDataIcon} alt="no-item-icon" />
          <p>{'No Items'}</p>
        </div>
      ) : (
        <Swiper
          modules={[Autoplay]}
          grabCursor={true}
          spaceBetween={0}
          slidesPerView={1}
          autoplay={{ delay: 3000 }}
          speed={1000}
          mousewheel={false}
          keyboard={true}
        >
          {data.results.map((item: any, i: number) => (
            <SwiperSlide key={i}>
              {({ isActive }) => (
                <MediaSlideItem
                  item={item}
                  className={`${isActive ? 'active' : ''}`}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
