import './ArtistList.scss';
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArtistCard } from '../../components';

export const ArtistList = (props: any) => {
  return (
    <div className="artist-list">
      <Swiper grabCursor={true} spaceBetween={10} slidesPerView={'auto'}>
        {props.artists &&
          props.artists.map((artist: any, i: number) => (
            <SwiperSlide key={i}>
              <ArtistCard item={artist} category={props.category} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};
