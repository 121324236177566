import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import { Button } from '../../../../components';
import '../../AddMedia.scss';
import { useEffect } from 'react';
import { resetSeasonState } from '../../../../app/slices/addTvShowSeasonSlice';
import {
  setCurrentTvShowSeasonEpisodeStep,
  setSelectedTvShowSeasonResult,
} from '../../../../app/slices/addTvShowSeasonEpisodeSlice';
import { setSelectedMediaType } from '../../../../app/slices/addMediaSlice';

export const Step7 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'instant',
    });
  }, []);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const newTvShowSeasonId = useAppSelector(
    (state: RootState) => state.addTvShowSeason.newTvShowSeasonId,
  );
  const generalTvShowSeasonData = useAppSelector(
    (state: RootState) => state.addTvShowSeason.generalTvShowSeasonData,
  );

  const addEpisodesSetup = () => {
    dispatch(
      setSelectedTvShowSeasonResult({
        id: newTvShowSeasonId,
        title: generalTvShowSeasonData.title,
      }),
    );
    dispatch(setCurrentTvShowSeasonEpisodeStep(2));
    dispatch(setSelectedMediaType('tvShowSeasonEpisode'));
  };

  const finalSetup = () => {
    navigate(`/tv-shows/season/${newTvShowSeasonId}`);
    dispatch(resetSeasonState());
  };
  return (
    <div className="add-media-form-container">
      <div className="buttons-container">
        <Button onClick={() => addEpisodesSetup()}>Add Episodes</Button>
        <Button onClick={() => finalSetup()}>Go To New Season Page</Button>
      </div>
    </div>
  );
};
