import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const TrailerModal = (props: any) => {
  const item = props.item;

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered={true}
        className="modal-dialog modal-xl"
      >
        <div style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
          <ModalHeader style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
            <div
              className="media-modal__content__close"
              role="button"
              tabIndex={0}
              onKeyDown={props.toggle}
              onClick={props.toggle}
            >
              <i className="bx bx-x"></i>
            </div>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#0f0f0f' }}>
            {item.trailerUrl ? (
              <iframe
                width="100%"
                height="500px"
                title="trailer"
                src={item.trailerUrl}
                allowFullScreen
              ></iframe>
            ) : (
              <p style={{ textAlign: 'center' }}>
                No trailer found for this movie.
              </p>
            )}
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};
