import { mediaManagerServerApi } from './mediaManagerServerApi';

export const extendedApiSlice = mediaManagerServerApi.injectEndpoints({
  endpoints: (builder) => ({
    getAutographs: builder.query<any, any | void>({
      query: (object) =>
        `autographs/?page=${object?.page || 1}&keyword=${object?.keyword || ''}&tag=${object?.tag || ''}&collection=${object?.collection || ''}`, //&page_size=12

      transformResponse: (rawResult: {
        results: any;
        currentPage: number;
        totalPages: number;
      }) => {
        // return rawResult.results;
        return {
          results: rawResult.results,
          currentPage: rawResult.currentPage,
          totalPages: rawResult.totalPages,
        };
      },
      // providesTags: ['Autograph'],
      // providesTags: (result = [], error, arg) => [
      // providesTags: (result = {}) => [
      //   'Autograph',
      //   ...result.results.map(({ id }: { id: number }) => ({
      //     type: 'Autograph',
      //     id,
      //   })),
      // ],

      // providesTags: (result, error, arg) =>
      providesTags: (result) =>
        result
          ? [
              ...result.results.map(({ id }: { id: number }) => ({
                type: 'Autograph' as const,
                id,
              })),
              'Autograph',
            ]
          : ['Autograph'],
      serializeQueryArgs: ({ queryArgs }) => {
        if (queryArgs) {
          const { page, ...otherArgs } = queryArgs;
          console.log('PAGE:', page);
          return otherArgs;
        } else {
          return queryArgs;
        }
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        //#1
        currentCache.results.push(...newItems.results);
        currentCache.currentPage = newItems.currentPage;
        //#2
        // if (newItems.currentPage > currentCache.currentPage) {
        //   currentCache.results.push(...newItems.results);
        //   currentCache.currentPage = newItems.currentPage;
        // } else {
        //   currentCache.results = newItems.results;
        //   currentCache.currentPage = newItems.currentPage;
        // }
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        //#1
        return currentArg?.page > previousArg?.page;
        //#2
        // return currentArg?.page !== previousArg?.page;
      },
    }),
    getAutograph: builder.query<any, string | undefined>({
      query: (id) => `autographs/${id}/`,
      // providesTags: ['Autograph'],
      // providesTags: (result, error, arg) => [{ type: 'Autograph', id: arg }]
      providesTags: (result, error, arg) => [{ type: 'Autograph', id: arg }],
    }),
  }),
});

export const { useGetAutographsQuery, useGetAutographQuery } =
  extendedApiSlice;
