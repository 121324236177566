// import { useEffect, useState } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router';
// import { useDispatch } from 'react-redux';
import '../Detail/Detail.scss';
import {
  Button,
  OutlineButton,
  ArtistList,
  TVShowList,
  EpisodeList,
  TrailerModal,
  MediaModal,
  LoadingSpinner,
} from '../../components';
import { Link } from 'react-router-dom';
import { useGetTVShowSeasonQuery } from '../../app/services/tvShowServerApi';
import errorIcon from '../../assets/error.svg';
import {
  getOriginalTitleFontSize,
  getOverviewFontSize,
  getTitleFontSize,
} from '../../shared/utils';

export const TVShowSeasonDetail = () => {
  const { category, id } = useParams();
  // const dispatch = useDispatch();
  const renderSimilarItems = () => {
    return (
      <TVShowList
        category={category}
        type="similar"
        id={tvShowSeasonItem.id}
      />
    );
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   // dispatch(fetchMediaDetailItem("seasons", seasonID))
  // }, [dispatch, seasonID]);

  const {
    data: tvShowSeasonItem = {},
    isLoading,
    // isFetching,
    isError,
    error,
  } = useGetTVShowSeasonQuery(id);

  const [openTVShowSeasonModal, setOpenTVShowSeasonModal] = useState(false);
  const toggleTVShowSeasonModal = () =>
    setOpenTVShowSeasonModal(!openTVShowSeasonModal);

  const [openTrailerModal, setOpenTrailerModal] = useState(false);
  const toggleTrailerModal = () => setOpenTrailerModal(!openTrailerModal);

  return (
    <div>
      {isLoading ? (
        <div className="media-slide-no-item-single">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="media-slide-no-item-single">
          <img src={errorIcon} alt="error-icon" />
          <p>
            {error?.status === 404
              ? 'Page Not Found'
              : error?.error
                ? error?.error
                : 'An error ocurred'}
          </p>
          {error?.status === 404 && (
            <Link className="media-a" to="/">
              <Button>Go Back to the Home Page</Button>
            </Link>
          )}
        </div>
      ) : (
        <>
          <div
            className="banner"
            style={{
              backgroundImage: `url(${tvShowSeasonItem.background ? tvShowSeasonItem.background : tvShowSeasonItem.cover})`,
            }}
          >
            <div className="movie-content media-container">
              <div className="movie-content__info">
                <h1
                  className="title"
                  style={{
                    fontSize: getTitleFontSize(
                      `Season ${tvShowSeasonItem.number}`,
                    ),
                  }}
                >
                  Season {tvShowSeasonItem.number}
                </h1>
                {tvShowSeasonItem.originalTitle ? (
                  <h3
                    className="original-title"
                    style={{
                      fontSize: getOriginalTitleFontSize(
                        tvShowSeasonItem.originalTitle,
                      ),
                    }}
                  >
                    Original Title: {tvShowSeasonItem.originalTitle}
                  </h3>
                ) : null}
                <h3
                  className="original-title"
                  style={{
                    fontSize: getOriginalTitleFontSize(
                      tvShowSeasonItem.title || tvShowSeasonItem.name,
                    ),
                  }}
                >
                  {tvShowSeasonItem.title || tvShowSeasonItem.name}
                </h3>

                <p
                  className="overview"
                  style={{
                    fontSize: getOverviewFontSize(
                      tvShowSeasonItem.description,
                    ),
                  }}
                >
                  {tvShowSeasonItem.description}
                </p>

                <div className="btns">
                  <Button onClick={toggleTVShowSeasonModal}>Watch now</Button>
                  <OutlineButton onClick={toggleTrailerModal}>
                    Watch trailer
                  </OutlineButton>{' '}
                </div>
              </div>

              <div className="movie-content__poster">
                <a
                  href={tvShowSeasonItem.cover}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="movie-content__poster__img_tv_show"
                    // style={{ backgroundImage: `url(${tvShowSeasonItem.cover})` }}
                    src={tvShowSeasonItem.cover}
                    alt={tvShowSeasonItem.title || tvShowSeasonItem.name}
                  ></img>
                </a>
              </div>
            </div>
          </div>

          <div className="movie-content-description">
            {tvShowSeasonItem.originalTitle ? (
              <h3
                className="original-title"
                style={{
                  fontSize: getOriginalTitleFontSize(
                    tvShowSeasonItem.originalTitle,
                  ),
                }}
              >
                Original Title: {tvShowSeasonItem.originalTitle}
              </h3>
            ) : null}
            <p
              className="overview"
              style={{
                fontSize: '1rem',
              }}
            >
              {tvShowSeasonItem.description}
            </p>
          </div>

          <div className="detail-container seasons-list">
            <div className="media-section">
              <div className="media-section__header">
                <h2 className="subtitle">Episodes</h2>
              </div>
              <EpisodeList
                tvShowID={tvShowSeasonItem.tvShow}
                seasonID={tvShowSeasonItem.id}
                episodes={tvShowSeasonItem.episodes}
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Producers'}</h2>
              </div>
              <ArtistList
                artists={tvShowSeasonItem.seasonProducers}
                category="producer"
              />
            </div>
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Cast'}</h2>
              </div>
              <ArtistList
                artists={tvShowSeasonItem.seasonActors}
                category="actor"
              />
            </div>
          </div>

          <div className="movie-content-details">
            <div className="movie-content-details__info">
              <div className="media-section__header">
                <h2 className="subtitle">{'Additional Information'}</h2>
              </div>
              <div className="genres">
                <span className="badge genres__item">
                  Network:{' '}
                  {tvShowSeasonItem.network && tvShowSeasonItem.network.name}
                </span>
              </div>
              <div className="genres">
                <p className="overview">
                  Copyright: {tvShowSeasonItem.copyright}
                </p>
              </div>
            </div>
          </div>

          <div className="detail-container">
            <div className="media-section mt-3">
              <div className="media-section__header">
                <h2 className="subtitle">Similar</h2>
              </div>
              {renderSimilarItems()}
            </div>
          </div>

          <TrailerModal
            isOpen={openTrailerModal}
            toggle={toggleTrailerModal}
            item={tvShowSeasonItem}
          />
          <MediaModal
            isOpen={openTVShowSeasonModal}
            toggle={toggleTVShowSeasonModal}
            item={tvShowSeasonItem}
          />
        </>
      )}
    </div>
  );
};
