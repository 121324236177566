import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';

export const MediaModal = (props: any) => {
  const item = props.item;
  const subtitles =
    props.category === 'movie'
      ? item?.movieLanguages?.map((movieLanguage: any) => ({
          kind: 'subtitles',
          src: movieLanguage?.subtitleFileVtt,
          srcLang: movieLanguage?.language.name,
        }))
      : item?.episodeLanguages?.map((episodeLanguage: any) => ({
          kind: 'subtitles',
          src: episodeLanguage?.subtitleFileVtt,
          srcLang: episodeLanguage?.language.name,
        }));
  const mediaURL = item.filePath;

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        centered={true}
        className="modal-dialog modal-xl"
      >
        <div style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
          <ModalHeader style={{ backgroundColor: '#0f0f0f', border: 'none' }}>
            <div
              className="media-modal__content__close"
              role="button"
              tabIndex={0}
              onKeyDown={props.toggle}
              onClick={props.toggle}
            >
              <i className="bx bx-x"></i>
            </div>
          </ModalHeader>
          <ModalBody style={{ backgroundColor: '#0f0f0f' }}>
            <ReactPlayer
              id="videomedia"
              config={{
                file: {
                  attributes: {
                    crossOrigin: 'true',
                  },
                  tracks: subtitles,
                },
              }}
              url={mediaURL}
              width="100%"
              height="100%"
              controls
            />
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};
